<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4 px-8">
      <v-row>
        <v-tabs color="success" class="custom-tabs">
          <v-tab>Тариф</v-tab>
          <v-tab>Статті</v-tab>

          <v-tab-item>
            <v-form v-model="formValid" ref="form">
              <v-row class="py-2 dense-wrapper mt-1">
                <v-col cols="12" md="12">
                  <AddressElementSelect v-model="building_id"
                                        label="Будинок(-ки)" filled req
                                        select_type="building"
                                        :auto_fill_when_first="true"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select :items="services" filled label="Послуга"
                            hide-details
                            v-model="service_id"
                            required :rules="[v => !!v || 'Це поле є обов’язковим']"
                            :class="service_id ? '' : 'req-star'"
                            color="grey"
                  />
                </v-col>
                <v-col cols="12">
                  <AC_TariffGroup :value="tariff_group_type_id" @autocompleteChange="autocompleteChange"/>
                </v-col>
                <v-col cols="6">
                  <date-component v-model="date_start" req label="Дата початку"/>
                </v-col>
                <v-col cols="6">
                  <date-component v-model="date_end" label="Дата закінчення"/>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field type="text" hide-details filled label="Тариф"
                                v-model="value_dec"
                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                :class="value_dec ? '' : 'req-star'"
                                color="grey"
                                v-decimal
                  />
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field type="text" hide-details filled label="Тариф 2 зона"
                                v-model="value_dec_second"
                                color="grey"
                                v-decimal
                  />
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field type="text" hide-details filled label="Тариф 3 зона"
                                v-model="value_dec_third"
                                color="grey"
                                v-decimal
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab-item>
            <div class="form-table-custom mt-3 pb-2 px-2 dense-wrapper">
              <div class="table-command-panel mb-3">
                <v-btn plain small class="grey lighten-3 mr-2" @click.stop="addRemovalItem">
                  <v-icon small color="success" left>mdi-plus</v-icon>
                  Додати
                </v-btn>
                <v-btn plain small class="grey lighten-3 mr-2" @click.stop="fillRemovals">
                  <v-icon small color="grey darken-2" left>mdi-plus</v-icon>
                  Заповнити
                </v-btn>
                <v-btn plain small class="grey lighten-3"
                       @click="onRemovalItemDelete"
                >
                  <v-icon small color="error" left>mdi-delete</v-icon>
                  Вилучити
                </v-btn>
              </div>
              <table>
                <thead>
                <tr>
                  <th style="width: 30px;"></th>
                  <th style="min-width: 70px;">№ п/п</th>
                  <th style="min-width: 400px;">Стаття тарифу</th>
                  <th style="min-width: 100px;">Тариф</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, idx) in removal_items" :key="`entrance-${idx}`">
                  <td style="width: 30px">
                    <v-checkbox hide-details class="ml-2 mt-0" :ripple="false" color="secondary"
                                :value="selected_removal_item.includes(item)"
                                @change="onRemovalItemSelect(item)"
                    />
                  </td>
                  <td style="min-width: 70px;">
                    <input type="text" v-integer disabled :value="idx + 1">
                  </td>
                  <td style="min-width: 400px;">
                    <AC_RemovalItem :value="item.removal_item_id"
                                    class="pt-0 mt-0"
                                    :row_num="idx" required
                                    clearable
                                    :tile="true"
                                    :filled="false"
                                    color=""
                                    label=""
                                    :style_="'font-size: 13px !important; padding-left: 6px !important; height: 100%; padding-left: 0'"
                                    @autocompleteChange="onRemovalItemChange"
                    />
                  </td>
                  <td style="min-width: 100px;">
                    <input type="text"
                           v-decimal
                           :class="!item.value_dec ? 'input-error' : ''"
                           v-model.number="item.value_dec">
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="updateService" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import {CREATE_TARIFF_BY_BUILDING, UPDATE_TARIFF_BY_BUILDING, DELETE_TARIFF_BY_BUILDING} from "@/store/actions/tariff_by_building";
import tariffAPI from "@/utils/axios/tariff_by_building";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";
import {GET_ALL_FLAT_TARIFF} from "@/store/actions/flat_service";

const modalDeleteId = 'tariff_by_building_modal_id'

export default {
  name: "HWP_Modal_TariffByBuilding",
  mixins: [ModalComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    AC_TariffGroup: () => import("@/components/autocomplite/AC_TariffGroup"),
    AC_RemovalItem: () => import("@/components/autocomplite/Removaltem")
  },
  data() {
    return {
      is_legacy: this.item.is_legacy || false,
      building_id: this.item.building_id || null,
      service_id: this.item.service_id || null,
      tariff_group_type_id: this.item.tariff_group_type_id || null,
      date_start: this.item.date_start || null,
      date_end: this.item.date_end || null,
      value_dec: this.item.value_dec || 0,
      value_dec_second: this.item.value_dec_second || 0,
      value_dec_third: this.item.value_dec_third || 0,
      removal_items: [],
      selected_removal_item: [],
      from_flat: this.item.from_flat || false
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll',
      groups: 'getTariffGroupSelect',
    }),
  },
  methods: {
    addRemovalItem() {
      this.removal_items.push(
          {
            removal_item_id: null,
            value_dec: 0,
          }
      )
    },
    onRemovalItemChange(payload) {
      const row_num = (payload || {}).row_num
      if (row_num !== null && row_num !== undefined) {
        this.removal_items[row_num].removal_item_id = payload.value || null
      }
    },
    onRemovalItemDelete() {
      if (!this.selected_removal_item.length) return
      this.selected_removal_item.forEach(item => {
        this.removal_items.splice(this.removal_items.indexOf(item), 1)
      })
      this.selected_removal_item = []
    },
    onRemovalItemSelect(payload) {
      if (this.selected_removal_item.includes(payload)) {
        this.selected_removal_item.splice(this.selected_removal_item.indexOf(payload), 1)
      } else {
        this.selected_removal_item.push(payload)
      }
    },
    getRemovals() {
      if (this.itemId) {
        tariffAPI.get_removal_items(this.itemId)
            .then(response => response.data)
            .then(data => {
              this.removal_items = data
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      }
    },
    fillRemovals() {
      tariffAPI.fill_removals()
          .then(response => response.data)
          .then(data => {
            this.removal_items = data
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    autocompleteChange(payload) {
      this.tariff_group_type_id = (payload || {}).value || null
    },
    buildingChange(payload) {
      this.building_id = (payload || {}).value || null
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.is_legacy = this.item.is_legacy || false
      this.building_id = this.item.building_id || null
      this.service_id = this.item.service_id
      this.tariff_group_type_id = this.item.tariff_group_type_id
      this.date_start = this.item.date_start
      this.date_end = this.item.date_end
      this.value_dec = this.item.value_dec
      this.value_dec_second = this.item.value_dec_second
      this.value_dec_third = this.item.value_dec_third
      this.removal_items = []
      this.from_flat = this.item.from_flat || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false

      const payload = {
        text: `Підтвердіть вилучення будинкового тарифу по послузі: ${this.getServiceName()}, значення: ${this.value_dec}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    updateService() {
      let add_text = ''
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false

        if (this.removal_items.length) {
          if (this.removal_items.filter(i => i.value_dec === null || i.value_dec === 0).length) {
            add_text = ', Тариф у таблиці статей'
          }
        }

        this.$store.commit(ALERT_SHOW, {
          text: `Будинок, Послуга, Дата початку ${add_text} мають бути заповнені`,
          color: 'error lighten-1'
        })
        return undefined
      } else {

        if (this.removal_items.length) {
          if (this.removal_items.filter(i => i.value_dec === null || i.value_dec === 0).length) {
            this.$store.commit(ALERT_SHOW, {
              text: 'Тариф у таблиці статей має бути заповнений',
              color: 'error lighten-1'
            })
            return undefined
          }
        }
      }

      const payload = {
        is_legacy: this.is_legacy || false,
        building_id: this.building_id,
        service_id: this.service_id,
        tariff_group_type_id: this.tariff_group_type_id,
        date_start: this.date_start,
        date_end: this.date_end,
        value_dec: this.value_dec,
        value_dec_second: this.value_dec_second,
        value_dec_third: this.value_dec_third,
        removals: this.removal_items
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_TARIFF_BY_BUILDING, payload)
            .then(el => {
              if (el) {
                if (this.from_flat && this.$route.params.person_hash) {
                  this.$store.dispatch(GET_ALL_FLAT_TARIFF, this.$route.params.person_hash)
                }
                this.$store.commit(ALERT_SHOW,
                    {
                      text: 'Тариф створено успішно. Виконайте загальний розрахунок по усіх абонентах',
                      color: 'success'
                    })
                this.closeModal()
              }
            })
      } else {
        payload['id'] = this.itemId

        this.$store.dispatch(UPDATE_TARIFF_BY_BUILDING, payload)
            .then(el => {
              if (el) {
                if (this.from_flat && this.$route.params.person_hash) {
                  this.$store.dispatch(GET_ALL_FLAT_TARIFF, this.$route.params.person_hash)
                }
                this.$store.commit(ALERT_SHOW,
                    {
                      text: 'Тариф оновлено успішно. Виконайте загальний розрахунок по усіх абонентах',
                      color: 'success'
                    })
                this.closeModal()
              }
            })
      }
    },
    getServiceName() {
      if (this.service_id) {
        const ind = this.services.find(item => item.value === this.service_id)
        if (ind) {
          return ind.text
        }
      }
      return ''
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.is_legacy = payload.is_legacy || false
              this.building_id = payload.building_id
              this.service_id = payload.service_id
              this.tariff_group_type_id = payload.tariff_group_type_id
              this.date_start = payload.date_start
              this.date_end = payload.date_end
              this.value_dec = payload.value_dec
              this.value_dec_second = payload.value_dec_second
              this.value_dec_third = payload.value_dec_third
              this.from_flat = payload.from_flat || false

              this.getRemovals()
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_TARIFF_BY_BUILDING, { id: this.itemId, building_id: this.building_id })
                      .then(ok => {
                        if (ok) {
                          if (this.from_flat && this.$route.params.person_hash) {
                            this.$store.dispatch(GET_ALL_FLAT_TARIFF, this.$route.params.person_hash)
                          }
                          this.$store.commit(ALERT_SHOW,
                              {
                                text: 'Тариф вилучено успішно. Виконайте загальний розрахунок по усіх абонентах',
                                color: 'success'
                              })
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
